import React from 'react'
import { QualityData, QualityDataProps } from '../../Cards/QualityData'
import { useLiveMonitor } from '../../../../Hooks/useLiveMonitor'
import { useTranslation } from 'react-i18next'

export const TeamsQualityCard = () => {
    const { t } = useTranslation('teamMonitor')
    const { stats } = useLiveMonitor()
    if (!stats) return null
    const { mild, moderate, severe } = stats.monitor.teams.teamByPeople

    const helpRequestInfo: QualityDataProps = {
        title: t('Teams by number of people'),
        status: [
            {
                icon: ['fas', 'check-circle'],
                label: t('From 6 to 8 people'),
                value: mild.length,
            },
            {
                icon: ['fas', 'play-circle'],
                label: t('From 3 to 5 people or 8 to 10 people'),
                value: moderate.length,
            },
            {
                icon: ['fas', 'circle-exclamation'],
                label: t('Less than 3 or more than 10 people'),
                value: severe.length,
            },
        ],
        info: t(
            'Team distribution by size provides a breakdown of the number of teams in your video call based on the number of people in each team.'
        ),
    }
    return <QualityData {...helpRequestInfo} />
}
