import React, { useContext } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Config from '../../../../../config'
import { downloadFile } from '../../../../../apis/api.egg'
import { useTeam } from '../../../Context/TeamContext'
import UIContext from '../../../../../context/UIContext'
import { useGAEvents } from '../../../../../hooks/useGAEvents'

export const ClassRoomReport = () => {
    const { t } = useTranslation('teamManager')
    const { team } = useTeam()
    const { toastNotification } = useContext(UIContext)
    const { sendGA } = useGAEvents()

    const onMapReport = (algorithmId: string | undefined, teamName: string) => {
        if (algorithmId === undefined)
            return toastNotification('Error algorithm undefined', 'error')

        const url = `${Config.API}/v3/algorithm/${algorithmId}/teamsMap`
        const today = dayjs.utc().format('YYYY-MM-DD')
        const fileName = `${teamName}_MapReport_${today}.xlsx`.replaceAll(' ', '_')
        downloadFile(url, fileName)
    }

    if (!team) return null

    const { meeting } = team.settings

    return (
        <button
            onClick={() => {
                onMapReport(team?.algorithm?._id, team.teamName)
                sendGA({ category: 'Reporting', action: 'TeamReportDownloaded' })
            }}
            disabled={meeting?.type !== 'zoom-hybrid'}
        >
            <FontAwesomeIcon className="icon" icon={['far', 'map']} />
            {t('heading.report.class-map')}
        </button>
    )
}
