import React, { useMemo, useState } from 'react'
import DayJS from 'dayjs'
import Modal from 'react-modal'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
DayJS.extend(relativeTime)
DayJS.extend(localizedFormat)
DayJS.extend(utc)

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import ConfirmModal from '../../../../../components/ConfirmModal'
import Config from '../../../../../config'
import { useUIContext } from '../../../../../context/UIContext'
import { CourseMonitorDTO } from '../../../../../apis/api.egg'
import { useTeam } from '../../../Context/TeamContext'

import Styles from './RequestFeedback.module.css'
import { useGAEvents } from '../../../../../hooks/useGAEvents'

export const RequestFeedback = (props: { variant: string; ableRequestFeedback: boolean }) => {
    const queryClient = useQueryClient()
    const [modalRequest, setModalRequest] = useState(false)
    const [modalDownload, setModalDownload] = useState(false)
    const [enableRequestFeedback, setEnableRequestFeedback] = useState<boolean | null>(
        props.ableRequestFeedback
    )

    const { toastError, toastNotification } = useUIContext()
    const { t } = useTranslation('teamMonitor')
    const { courseID, team } = useTeam()

    const { sendGA } = useGAEvents()

    const queryKey = [courseID, 'Team', 'Monitor', 'Get Stats']

    const { variant } = props

    const disabledButton = !enableRequestFeedback || modalRequest

    const { mutate } = useMutation(
        [courseID, 'Team', 'Feedback', 'Request'],
        (courseID: string) =>
            Axios.post<EnableFeedbackResponse>(
                `${Config.API}/v3/course/${courseID}/enableFeedback`
            ).then(({ data }) => data.message),

        {
            onMutate: () => {
                setEnableRequestFeedback(false)
                setModalRequest(false)

                sendGA({
                    category: 'Team Management',
                    action: 'FeedbackRequest',
                })

                const snapshot = queryClient.getQueryData<CourseMonitorDTO>(queryKey)
                snapshot &&
                    queryClient.setQueryData<CourseMonitorDTO | undefined>(queryKey, (prev) =>
                        prev
                            ? {
                                  ...prev,
                                  ableRequestFeedback: false,
                              }
                            : prev
                    )
                return snapshot
            },
            onSuccess: () => toastNotification(t('Class feedback has been solicited')),
            onError(error, variables, snapshot) {
                queryClient.setQueryData(queryKey, snapshot)
                toastError(error)
            },
        }
    )

    if (!courseID || !team?.settings?.enableRequestFeedback) return null
    return (
        <>
            {variant === 'request' && (
                <button
                    key={'Request'}
                    onClick={() => setModalRequest(true)}
                    disabled={disabledButton}
                >
                    <FontAwesomeIcon icon={['far', 'message']} />
                    {t('Student feedback_other')}
                </button>
            )}

            {variant === 'download' && (
                <button key={'Download'} onClick={() => setModalDownload(true)}>
                    <FontAwesomeIcon icon={['far', 'message']} />
                    {t('Download feedback')}
                </button>
            )}

            <ConfirmModal
                open={modalRequest}
                onConfirm={() => mutate(courseID)}
                onClose={() => setModalRequest(false)}
                title={t('Request feedback')}
                contentText={t('¿Do you want to ask students for class feedback?')}
                confirmButtonText={t('Request')}
                cancelButtonText={t('Cancel')}
            />

            <DownloadFeedback isOpen={modalDownload} onClose={() => setModalDownload(false)} />
        </>
    )
}

// eslint-disable-next-line no-undef
const DownloadFeedback = ({ onClose, ...rest }: { onClose?: () => void } & ReactModal.Props) => {
    const { toastNotification, toastError } = useUIContext()
    const { courseID } = useTeam()
    const [selectedDate, setSelectedDate] = useState(DayJS().format('YYYY-MM-DD'))
    const { t } = useTranslation(['teamMonitor', 'notifications'])
    const { sendGA } = useGAEvents()

    const isValid = DayJS(selectedDate).isValid()
    const utcOffset = DayJS().utcOffset() / 60
    const date = DayJS(selectedDate).format('DD/MM/YYYY')
    const url = `${Config.API}/v3/course/${courseID}/exportFeedback?startDate=${date}&endDate=${date}&timezone=${utcOffset}`

    const today = useMemo(() => DayJS().format('YYYY-MM-DD'), [])

    const { mutate: downLoadReport } = useMutation(
        [courseID, 'Team', 'Feedback', 'Download Report'],
        () => Axios.get<DownloadFeedbackResponse>(url),
        {
            onMutate: () => {
                sendGA({
                    category: 'Reporting',
                    action: 'FeedbackDownload',
                })
            },
            onSuccess: () => {
                toastNotification(
                    t("We'll notify you when the document report is available for download.", {
                        ns: 'notifications',
                    })
                )
                onClose?.()
            },
            onError: toastError,
        }
    )

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            inset: 0,
            backgroundColor: '#37474feb',
            zIndex: 2,
        },
        content: {
            top: 70,
            inset: 0,
            margin: 'auto',
            width: 'fit-content',
            height: 'fit-content',
            borderRadius: 8,
        },
    }

    return (
        <Modal {...rest} style={customStyles} onRequestClose={onClose}>
            <div className={Styles.downloadFeedback}>
                <h4>{t('Download feedback')}</h4>
                <hr />
                <fieldset>
                    <label htmlFor="date">{t('Select date')}</label>
                    <input
                        type="date"
                        name="date"
                        id="date"
                        value={selectedDate}
                        max={today}
                        onChange={({ currentTarget: { value } }) => setSelectedDate(value)}
                    />
                </fieldset>
                <div className={Styles.warning}>
                    <FontAwesomeIcon
                        icon={['fal', 'circle-exclamation']}
                        style={{ fontSize: 30, marginRight: 15 }}
                    />
                    <div className={Styles.message}>
                        <div>{t('The full feedback report of the day will be available')}</div>
                        <div>
                            <u>{t('1 hour after class ends')}</u>.
                        </div>
                    </div>
                </div>
                <div className={Styles.buttons}>
                    <button className={Styles.cancel} onClick={onClose}>
                        {t('Cancel')}
                    </button>
                    <button
                        className={Styles.download}
                        disabled={!isValid}
                        onClick={() => isValid && downLoadReport()}
                    >
                        {t('Download')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

interface EnableFeedbackResponse {
    data: {
        expirationDateFeedback: string
    }
    message: string
}
interface DownloadFeedbackResponse {
    data: Record<string, unknown>
    message: string
}
