import React from 'react'
import { ProgressBarsProps, ProgressBars } from '../../Cards/ProgressBars'
import { useLiveMonitor } from '../../../../Hooks/useLiveMonitor'
import { useTranslation } from 'react-i18next'

export const AttendanceProgressCard = () => {
    const { t } = useTranslation('teamMonitor')
    const { stats } = useLiveMonitor()
    if (!stats) return null
    const { attendance, teams } = stats.monitor

    const { present, presentPercentage, absent, absentPercentage } = attendance

    const teamsInfo: ProgressBarsProps = {
        title: t('Assistance'),
        subtitle: t(`Out of a total of {{ totalStudents }} who started`, {
            totalStudents: teams.totalStudents,
        }),
        // TODO: Define the info prop and translate it
        info: t('Attendance status: present and absent participants in the video call.'),
        metrics: [
            {
                icon: ['fas', 'user-check'],
                caption: `${t('Assistants')} ${present}`,
                value: Intl.NumberFormat().format(present),
                percentage: presentPercentage,
                variant: 'success',
            },
            {
                icon: ['fas', 'user-clock'],
                caption: `${t('Absentees')} ${absent}`,
                value: Intl.NumberFormat().format(absent),
                percentage: absentPercentage,
                variant: 'danger',
            },
        ],
    }
    return <ProgressBars {...teamsInfo} />
}
