import React, { ReactNode } from 'react'
import styles from './QualityData.module.css'
import { CardInfo, CardsHeader } from './CardsHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const QualityData = ({ title, status, info }: QualityDataProps) => {
    return (
        <section className={styles.card}>
            <CardsHeader title={title} />
            <div className={styles.itemsContainer}>
                {status.map(({ icon, label, value }, i) => (
                    <div key={i} className={styles.item}>
                        <div className={styles.col1}>
                            <FontAwesomeIcon
                                icon={icon}
                                className={`
                                ${i === 0 ? 'success' : i === 1 ? 'yellowEgg' : 'danger'} 
                                ${styles.icon}`}
                            />
                            <p>{label}</p>
                        </div>
                        <div className={styles.value}>{value}</div>
                    </div>
                ))}
            </div>
            <CardInfo info={info} />
        </section>
    )
}

// Interface
export interface QualityDataProps {
    title: ReactNode
    status: {
        icon: IconProp
        label: string
        value: ReactNode
    }[]
    info: string
}
