import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import {
    AttendanceStatus,
    GetTeamDetailResponse,
    updateAttendance,
} from '../../../../../apis/api.egg'
import { BtnCircular } from '../../../../../components/Buttons/BtnCircular'
import { useUIContext } from '../../../../../context/UIContext'
import { useTeam } from '../../../Context/TeamContext'
import { StudentItemProps } from '../StudentItem'

export const Attendance = ({ _profileId, attendance, team: teamInfo }: StudentItemProps) => {
    const { toastError } = useUIContext()
    const { t } = useTranslation('teamManager')
    const { team, courseID } = useTeam()
    const [status, setStatus] = useState(attendance?.status)

    const comment = attendance?.comment || ''

    useEffect(() => {
        setStatus(attendance?.status)
    }, [attendance?.status])

    const commonStyles: React.CSSProperties = {
        border: 0,
        width: 'auto',
        height: 'auto',
        fontSize: 23,
        color: '#dadada',
    }
    const todayUTC = dayjs().utc().format('DD/MM/YYYY')

    const key = [courseID, 'Team', 'Details', teamInfo?.number]
    const queryClient = useQueryClient()
    const { mutate } = useMutation(
        [courseID, 'Team', 'Attendance update', _profileId],
        (params: Parameters<typeof updateAttendance>[0]) => updateAttendance(params),
        {
            onMutate: async (params) => {
                const { status, comment } = params
                await queryClient.cancelQueries([courseID, 'Team', 'Attendance update'])
                const snapshot = queryClient.getQueryData<GetTeamDetailResponse>(key)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                queryClient.setQueryData<typeof snapshot>(key, (prev: { students: any[] }) =>
                    prev
                        ? {
                              ...prev,
                              students: prev.students.map((student) =>
                                  student._profileId === _profileId
                                      ? { ...student, attendance: { status, comment } }
                                      : student
                              ),
                          }
                        : prev
                )
                return snapshot
            },
            onError: (error, param, snapshot) => {
                queryClient.setQueryData(key, snapshot)
                toastError(error)
            },
            onSettled: () => {
                queryClient.invalidateQueries([courseID, 'Team', 'Monitor', 'Get Stats'])
                queryClient.invalidateQueries([courseID, 'Team', 'Unassigned'], { exact: false })
                queryClient.invalidateQueries([courseID, 'Team', 'Search', 'people'], {
                    exact: false,
                })
            },
        }
    )

    const changeAttendance = (status: AttendanceStatus) => {
        if (!team || !_profileId || !status) return
        const {
            professorSchedule: { _id: _professorScheduleId },
            subject: { _id: _subjectId },
        } = team
        setStatus(status)
        mutate({
            _professorScheduleId,
            _subjectId,
            _studentId: _profileId,
            date: todayUTC,
            status,
            comment,
        })
    }

    return (
        <div style={{ marginRight: '1.5em' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
                <BtnCircular
                    icon={['fal', 'user-check']}
                    iconOver={['fas', 'user-check']}
                    isActive={status === 'present'}
                    toolTips={t('actions.attendance.setAttendance')}
                    onClick={() => changeAttendance('present')}
                    style={{
                        ...commonStyles,
                        color: status !== 'present' ? '#d2d2d2' : '#7de780',
                    }}
                />
                <BtnCircular
                    icon={['fal', 'user-slash']}
                    iconOver={['fas', 'user-slash']}
                    isActive={status === 'absent'}
                    toolTips={t('actions.attendance.setAsAbsent')}
                    onClick={() => changeAttendance('absent')}
                    style={{
                        ...commonStyles,
                        color: status !== 'absent' ? '#d2d2d2' : '#ee8686',
                    }}
                />
            </div>
        </div>
    )
}
