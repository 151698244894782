import React, { ReactNode } from 'react'
import Modal from 'react-modal'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

export const ConfirmModal = ({
    type = 'normal',
    title,
    contentText,
    open,
    confirmButtonText,
    cancelButtonText,
    onClose,
    onConfirm,
    disabled = false,
}: ConfirmProps) => {
    const fontAwesome: CustomIconProps = {
        normal: { icon: ['fas', 'exclamation-circle'], className: 'normal' },
        warning: { icon: ['fas', 'exclamation-triangle'], className: 'warning' },
        danger: { icon: ['fas', 'exclamation-circle'], className: 'danger' },
    }

    return (
        <Modal
            isOpen={open}
            onRequestClose={onClose}
            className="modal-confirm"
            ariaHideApp={false}
            style={{
                overlay: {
                    zIndex: 1,
                },
            }}
        >
            <div className="modal-div">
                <div className="modal-title">
                    <h3>{title}</h3>
                    <FontAwesomeIcon
                        className={fontAwesome[type].className}
                        icon={fontAwesome[type].icon}
                    />
                </div>
                <hr />
                <div className="modal-content">
                    <div>{contentText}</div>
                </div>

                <div className="div-buttons">
                    <button className="btn-modal-cancel" onClick={onClose}>
                        {cancelButtonText}
                    </button>
                    {confirmButtonText && (
                        <button
                            className="btn-modal-confirm"
                            onClick={onConfirm}
                            disabled={disabled}
                        >
                            {confirmButtonText}
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmModal

// Interfaces

export interface ConfirmProps {
    type?: 'normal' | 'warning' | 'danger'
    title: string
    contentText?: string | ReactNode
    open: boolean
    confirmButtonText?: string
    cancelButtonText: string
    onClose?: () => void
    onConfirm?: () => void
    disabled?: boolean
}

type CustomIconProps = Record<
    'normal' | 'danger' | 'warning',
    {
        icon: FontAwesomeIconProps['icon']
        className?: FontAwesomeIconProps['className']
    }
>
