import { ClassRoomReport } from './ClassRoomReport'
import Config from '../../../../../config'
import { CourseMonitorDTO } from '../../../../../apis/api.egg'
import DayJS from 'dayjs'
import { DropdownButton } from '../../../../../components/DropdownButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-modal'
import { RequestFeedback } from './RequestFeedback'
import Styles from './MonitorNavigation.module.css'
import axios from 'axios'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isYesterday from 'dayjs/plugin/isYesterday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import { useGAEvents } from '../../../../../hooks/useGAEvents'
import { useState } from 'react'
import { useTeam } from '../../../Context/TeamContext'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../context/UIContext'
import utc from 'dayjs/plugin/utc'

DayJS.extend(relativeTime)
DayJS.extend(localizedFormat)
DayJS.extend(utc)
DayJS.extend(isSameOrBefore)
DayJS.extend(isSameOrAfter)
DayJS.extend(isYesterday)
DayJS.extend(utc)
DayJS.extend(timezone)

export const MonitorNavigation = (
    props?: Pick<
        CourseMonitorDTO,
        'ableDownloadClassSummary' | 'ableRequestFeedback' | 'algorithmStatistics'
    >
) => {
    const { team } = useTeam()
    const { t } = useTranslation('teamMonitor')
    const { meeting } = team?.settings ?? {}

    const reportsOptions = []
    meeting?.type === 'zoom-hybrid' &&
        reportsOptions.push(<ClassRoomReport key={'ClassRoomReport'} />)
    team?.settings?.enableRequestFeedback &&
        reportsOptions.push(
            <RequestFeedback
                ableRequestFeedback={!!props?.ableRequestFeedback}
                variant={'download'}
                key={'DownloadFeedback'}
            />
        )
    props?.ableDownloadClassSummary && reportsOptions.push(<ClassSummary key={'ClassSummary'} />)

    const moreOptions = []
    team?.settings?.enableRequestFeedback &&
        moreOptions.push(
            <RequestFeedback
                ableRequestFeedback={!!props?.ableRequestFeedback}
                variant={'request'}
                key={'RequestFeedback'}
            />
        )

    return (
        <div className={Styles.container}>
            <div className={Styles.infoText}>{props && <LastRotationInfo {...props} />}</div>
            <nav className={Styles.buttons}>
                {/* <InviteLinks />*/}

                <DropdownButton
                    label={t('Reports')}
                    icon={['far', 'chart-line']}
                    options={reportsOptions}
                />
                <DropdownButton
                    label={t('More Functions')}
                    icon={['far', 'plus-circle']}
                    options={moreOptions}
                />
            </nav>
        </div>
    )
}

const LastRotationInfo = ({
    algorithmStatistics,
}: Pick<CourseMonitorDTO, 'algorithmStatistics'>) => {
    const { t } = useTranslation('teamMonitor')

    const lastRotation = algorithmStatistics?.rotation
    const lastRotationDayJS = DayJS(lastRotation?.lastTime)
    const rotationCount = lastRotation?.count
    const lastRotationAgo = lastRotationDayJS.isToday()
        ? '0'
        : lastRotationDayJS.isYesterday()
        ? '1'
        : lastRotationDayJS.fromNow(true)

    const lastRotationDate = lastRotationDayJS.local().format('L')
    const lastRotationTime = lastRotationDayJS.local().format('LT')

    return (
        <>
            {lastRotation ? (
                <>
                    {t('Last rotation n day ago, on DD at TT', {
                        count: Number(lastRotationAgo),
                        lastRotationAgo,
                        lastRotationDate,
                        lastRotationTime,
                    })}
                    . <b>{t('n accumulated rotations', { rotationCount })}</b>
                </>
            ) : (
                `${t("There hasn't executed a rotation yet")}.`
            )}
        </>
    )
}

const ClassSummary = () => {
    const { courseID } = useTeam()
    const { t } = useTranslation('teamMonitor')
    const [openModal, setOpenModal] = useState(false)
    const { toastNotification, toastError } = useUIContext()
    const [dateFrom, setDateFrom] = useState(DayJS().format('YYYY-MM-DD'))
    const { sendGA } = useGAEvents()

    const onConfirm = () => {
        const isValidDateRange =
            DayJS(dateFrom).isSameOrBefore(DayJS()) &&
            DayJS(dateFrom).isSameOrAfter(DayJS().subtract(1, 'month'))

        if (!isValidDateRange) return toastNotification(t('Invalid date'), 'error', 2)

        setOpenModal(false)
        sendGA({ category: 'Reporting', action: 'ClassSummaryDownload' })

        const date = DayJS(dateFrom).format('DD/MM/YYYY')
        const timezoneOffset = new Date().getTimezoneOffset() / 60 // Get the offset in minutes, then divide by 60 to get the hours
        const endpoint = `${
            Config.API
        }/v3/course/${courseID}/getCourseSummary?startDate=${date}&endDate=${date}&timezone=${-timezoneOffset}`

        axios
            .get(endpoint)
            .then(() => toastNotification(t('Loading data, wait a moment please'), 'success'))
            .catch(toastError)
    }

    const onClose = () => {
        setOpenModal(false)
    }

    const handleOpenModal = () => {
        setOpenModal(true)
        sendGA({ category: 'Reporting', action: 'ClassSummary' })
    }

    return (
        <div>
            <button onClick={handleOpenModal}>
                <FontAwesomeIcon icon={['far', 'list']} />
                {t('Meeting summary')}
            </button>
            <Modal
                isOpen={openModal}
                onRequestClose={onClose}
                className="modal-dates"
                ariaHideApp={false}
                style={{
                    content: {},
                    overlay: {
                        zIndex: 1,
                        background: '#003750e6',
                        backdropFilter: 'blur(10px)',
                    },
                }}
            >
                <div className="modal-div">
                    <div className="modal-title">
                        <span> {t('Meeting summary')}</span>
                    </div>
                    <hr />
                    <div className="modal-content">
                        {t('Download a general summary of all the activity from a specific class')}
                    </div>
                    <div className="modal-big-date">
                        <label
                            htmlFor="from"
                            className="modal-big-date-picker"
                            style={{ border: '1px solid #DEDEDE', borderRadius: 10 }}
                        >
                            <span className="spanUpperDate">{t('Select date')}</span>
                            <input
                                type="date"
                                id="from"
                                value={dateFrom}
                                className="inputDates"
                                onChange={({ target }) => {
                                    setDateFrom(target.value)
                                }}
                            />
                        </label>
                    </div>

                    <div className="div-buttons">
                        <button className="btn-modal-cancel" onClick={onClose}>
                            {t('Cancel')}
                        </button>
                        <button className="btn-modal-confirm" onClick={onConfirm}>
                            {t('Download')}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
