import HeaderBanner from '../../../../components/DesignSystem/components/molecules/HeaderBanner/HeaderBanner.component'
import { ManageMeetingButton } from './ManageMeeting/ManageMeeting.button'
import { MeetButton } from '../MeetButton'
import { SpreadMessageButton } from './SpreadMessage/SpreadMessageButton'
import { useTeam } from '../../Context/TeamContext'
import { useTranslation } from 'react-i18next'

export const OverviewHeader = () => {
    // const { t } = useTranslation(['teamMonitor'])
    const { team } = useTeam()

    return (
        <HeaderBanner
            actions={
                <>
                    <SpreadMessageButton />
                    <ManageMeetingButton />
                    <MeetButton />
                </>
            }
            title={team?.teamName}
            // subtitle={t('Meeting monitor')}
        />
    )
}
