import React from 'react'
import { useLiveMonitor } from '../../../../Hooks/useLiveMonitor'
import { OneData, OneDataProps } from '../../Cards/OneData'
import { useTranslation } from 'react-i18next'

export const TeamsLiveCard = () => {
    const { t } = useTranslation('teamMonitor')
    const { stats } = useLiveMonitor()
    if (!stats) return null
    const { totalTeams, averagePeoplePerTeam } = stats.monitor.teams

    const teamsInfo: OneDataProps = {
        title: t('Teams'),
        info: t(
            "Number of teams formed and average number of students per team who have attended today's session."
        ),
        caption: '',
        data: totalTeams,
        footerIcon: ['fas', 'user'],
        footerText: t('Average people per team'),
        footerData: Intl.NumberFormat().format(averagePeoplePerTeam),
    }
    return <OneData {...teamsInfo} />
}
