import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useLocation } from 'wouter'
import { defaultModalStyles } from '../../../../../../constants'
import { colors } from '../../../../../../styles/globals/_variables'
import { useLiveMonitor } from '../../../../Hooks/useLiveMonitor'
import { useTeam } from '../../../../Hooks/useTeam'
import { QualityData, QualityDataProps } from '../../Cards/QualityData'
import styles from './../../LiveMonitor.module.css'

export const TalkingQualityCard = () => {
    const { t } = useTranslation('teamMonitor')
    const [currentQualityLevel, setCurrentQualityLevel] = useState<'hight' | 'moderate' | 'low'>()
    const [teams, setTeams] = useState<string[] | null>(null)
    const { stats } = useLiveMonitor()
    if (!stats) return null
    const { mild, moderate, severe } = stats.monitor.talkingPeople.qualityTeams

    const helpRequestInfo: QualityDataProps = {
        title: t('Team speaking quality'),
        status: [
            {
                icon: ['fas', 'check-circle'],
                label: t('More than 50% spoke'),
                value: (
                    <button
                        onClick={() => {
                            setCurrentQualityLevel('hight')
                            setTeams(mild)
                        }}
                        className={styles.titleLink}
                        disabled={!mild.length}
                    >
                        {mild.length}
                    </button>
                ),
            },
            {
                icon: ['fas', 'play-circle'],
                label: t('Between 10% and 50% spoke'),
                value: (
                    <button
                        onClick={() => {
                            setCurrentQualityLevel('moderate')
                            setTeams(moderate)
                        }}
                        className={styles.titleLink}
                        disabled={!moderate.length}
                    >
                        {moderate.length}
                    </button>
                ),
            },
            {
                icon: ['fas', 'circle-exclamation'],
                label: t('Less than 10% spoke'),
                value: (
                    <button
                        onClick={() => {
                            setCurrentQualityLevel('low')
                            setTeams(severe)
                        }}
                        className={styles.titleLink}
                        disabled={!severe.length}
                    >
                        {severe.length}
                    </button>
                ),
            },
        ],
        info: t(
            'This graph shows the percentage of teams where a certain percentage of team members spoke during the video call. Click on any of the numbers below to view the associated teams. Within the modal, you can select a team, and upon doing so, you will be redirected to the Teams screen with the team already filtered.'
        ),
    }
    return (
        <>
            <QualityData {...helpRequestInfo} />
            {currentQualityLevel && (
                <TalkingQualityModal
                    teams={teams}
                    currentModal={currentQualityLevel}
                    closeModal={() => setCurrentQualityLevel(undefined)}
                />
            )}
        </>
    )
}

const TalkingQualityModal = ({
    teams,
    currentModal,
    closeModal,
}: {
    teams: string[] | null
    currentModal: string
    closeModal: () => void
}) => {
    const { t } = useTranslation('teamMonitor')
    const [, setLocation] = useLocation()
    const { courseID } = useTeam()

    const iconToUse =
        currentModal === 'hight'
            ? 'check-circle'
            : currentModal === 'moderate'
            ? 'play-circle'
            : 'circle-exclamation'

    const colorToUse =
        currentModal === 'hight'
            ? colors.positiveGreen
            : currentModal === 'moderate'
            ? colors.eggColor
            : colors.salmon

    return (
        <Modal isOpen={!!currentModal} style={defaultModalStyles}>
            <FontAwesomeIcon
                icon={['fas', 'times']}
                onClick={closeModal}
                className={styles.closeIcon}
            />
            <div className={styles.modal}>
                <h4>
                    <FontAwesomeIcon
                        icon={['fas', iconToUse]}
                        color={colorToUse}
                        style={{ marginRight: 10 }}
                    />
                    {t(`Teams with ${currentModal} quality`)}
                </h4>
                <div className={styles.table}>
                    {teams?.map((team) => (
                        <div
                            key={team}
                            className={styles.talkingQualityRow}
                            onClick={() => setLocation(`/admin/team/${courseID}?team=${team}`)}
                        >
                            <div className={styles.talkingQualityItem}>
                                <p className={styles.buttonTitle}>
                                    {t('Team')} {team}
                                </p>
                                <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    )
}
