import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useUIContext } from '../../../context/UIContext'
import { getTeamDetails, GetTeamResponse } from '../../../apis/api.egg'
import { useTeam } from '../Context/TeamContext'
import { StudentItem } from './StudentItem/StudentItem'
import TeamHeader from './TeamHeader'

const TeamItem = ({ team, isExpanded }: TeamItemProps) => {
    const [listExpanded, setListExpanded] = useState(!!isExpanded)
    const toggleExpand = () => setListExpanded((prev) => !prev)

    return (
        <div className="table-item">
            <TeamHeader {...team} toggleExpand={toggleExpand} isExpanded={listExpanded} />
            {listExpanded && <StudentList teamNumber={team.number} />}
        </div>
    )
}

export const StudentList = ({ teamNumber }: { teamNumber: number }) => {
    const { t } = useTranslation('teamManager')
    const { courseID, team } = useTeam()
    const { toastError } = useUIContext()

    const { data, isLoading } = useQuery(
        [courseID, 'Team', 'Details', teamNumber],
        () => getTeamDetails(`${courseID}`, teamNumber),
        {
            enabled: !!courseID,
            onError: (error) => toastError(error),
        }
    )

    return (
        <div className="user-list">
            {isLoading && (
                <div style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon icon="spinner" spin />
                </div>
            )}
            {
                // No student in team
                !data?.students.length && !isLoading && (
                    <div style={{ textAlign: 'center' }}>
                        <p>{t('empty.empty-team')}</p>
                    </div>
                )
            }

            {data?.students?.map((student, index) => (
                <StudentItem
                    key={index + student._profileId}
                    {...student}
                    team={{
                        number: teamNumber,
                        position: index + 1,
                    }}
                    algorithmType={team?.algorithm?.algorithmType}
                    canDelete={true}
                />
            ))}
        </div>
    )
}
export default TeamItem

// Interfaces
interface TeamItemProps {
    team: GetTeamResponse['data']['teams'][0]
    isExpanded?: boolean
}
