import React, { useEffect } from 'react'
import Styles from './ShowSearchResult.module.css'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUIContext } from '../../../context/UIContext'
import { StudentItem } from './StudentItem/StudentItem'
import { useTeam } from '../Context/TeamContext'
import { StudentList } from './TeamItem'
import { useQuery } from 'react-query'
import { getPeopleSearch, getTeamDetails } from '../../../apis/api.egg'
import Accordion from '../../../components/DesignSystem/components/molecules/Accordion/Accordion.component'
import TeamHeader from './TeamHeader'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useGAEvents } from '../../../hooks/useGAEvents'

export const ShowSearchResult = () => {
    const { team, courseID, setIsSearching } = useTeam()
    const { searchBox, toastNotification } = useUIContext()
    const { t } = useTranslation(['teamManager'])
    const { sendGA } = useGAEvents()

    const { type, query } = searchBox[0] ?? {}
    // eslint-disable-next-line no-undef
    const { data: results, isLoading } = useQuery<Awaited<ReturnType<typeof getSearchResults>>>(
        [`${courseID}`, 'Team', 'Search', type, query],
        () => getSearchResults(searchBox[0]),
        {
            enabled: !!type && !!query?.trim(),
        }
    )
    useEffect(() => {
        setIsSearching(isLoading)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    const getSearchResults = (
        params: {
            type: 'people' | 'teams'
            query: string | number
        } | null
    ) => {
        if (!team || !params) return Promise.resolve(null)

        const { allTeamNumbers } = team.stats
        const { type, query } = params

        // Check if valid team number
        const reg = new RegExp('^[0-9]*$')
        const isValidTeam = allTeamNumbers.some((team) => team === +query)

        if (type === 'teams' && (!reg.test(`${query}`) || !isValidTeam)) {
            searchBox[1](null)
            toastNotification(t('search.error-invalid-team'), 'error')
            return null
        }

        sendGA({
            category: 'Team Management',
            action: 'SearchBar',
            label: type === 'teams' ? 'team' : 'team member',
        })

        // Searching people
        if (type === 'people')
            return getPeopleSearch(`${courseID}`, `${query}`).then((data) => ({
                type,
                ...data,
            }))

        // Searching teams
        if (type === 'teams')
            return getTeamDetails(`${courseID}`, `${query}`).then((data) => ({ type, ...data }))

        return Promise.resolve(null)
    }

    if (!results) return null

    const { isMentorTeam, guide, module, description } = results

    const icon: IconProp = ['fas', 'users']
    const subtitle = isMentorTeam ? `${t('search.mentors')}` : undefined
    const title = `${t('teamsList.team')} ${results.number}`
    const contentTitle = `${guide ? `${t('teamsList.guide')} ${guide}` : ''} ${
        module
            ? guide
                ? ` / ${t('teamsList.module')} ${module}`
                : `${t('teamsList.module')} ${module}`
            : ''
    } ${description ? (guide || module ? ` / ${description}` : description) : ''}`

    return (
        <section className={Styles.section}>
            <header className={Styles.mainHeader}>
                <h3>{t('search.results-title')}</h3>
                <button className={Styles.btnClearResult} onClick={() => searchBox[1](null)}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                </button>
            </header>

            <div className={Styles.results}>
                {
                    // Search by teams
                    results?.type === 'teams' && (
                        <Accordion
                            key={results.number}
                            header={{ contentTitle, icon, subtitle, title }}
                            headerChildren={<TeamHeader {...results} />}
                            notCollapsed={true}
                        >
                            <StudentList teamNumber={results.number} />
                        </Accordion>
                    )
                }

                {
                    // Search by People
                    results.type === 'people' &&
                        results.students.map((student) => (
                            <StudentItem
                                key={student._profileId}
                                {...student}
                                showTeamBadge={searchBox[0]?.type === 'people'}
                                canDelete={true}
                                algorithmType={team?.algorithm?.algorithmType}
                                className={'peopleResults'}
                            />
                        ))
                }
                {
                    // No student match
                    results.type === 'people' && !results.students.length && (
                        <h4>{t('search.no-results')}</h4>
                    )
                }
            </div>
        </section>
    )
}
