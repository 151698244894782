import Axios from 'axios'
import Config from '../config'
const { API_PARTICIPATION } = Config

export const ApiParticipation = Axios.create({
    baseURL: API_PARTICIPATION,
})

export const getCourseLiveMonitorData = () =>
    ApiParticipation.get<MonitoringData>('participation/monitoring/get_course_information').then(
        (data) => data.data
    )

export const getMonitoringArrivalUsers = () =>
    ApiParticipation.get<(number | string)[][]>('participation/monitoring/get_users_arrival').then(
        (data) => data.data
    )

export interface MonitoringData {
    ableRequestFeedback: boolean
    ableDownloadClassSummary: boolean
    algorithmStatistics: {
        rotation: {
            count: number
            lastTime: string
        }
        reset: {
            count: number
            lastTime: string
        }
    }
    monitor: {
        attendance: {
            present: number
            presentPercentage: number
            absent: number
            absentPercentage: number
        }
        pulses: {
            pulsesTotal: number
            pulsesAvailable: number
            qaPulses: {
                mild: number
                moderate: number
                severe: number
            }
            qaConnections: {
                mild: number
                moderate: number
                severe: number
            }
        }
        teams: {
            totalStudents: number
            totalTeams: number
            averagePeoplePerTeam: number
            teamByPeople: {
                mild: string[]
                moderate: string[]
                severe: string[]
            }
        }
        helpRequests: {
            total: number
            resolved: number
            inProgress: number
            pending: number
            expired: number
        }
        talkingPeople: {
            total: number
            qualityTeams: {
                mild: string[]
                moderate: string[]
                severe: string[]
            }
        }
    }
}

interface MonitoringArrivalUsers {
    attendancePlot: (number | string)[][]
}
